<template>
  <div>
    <b-modal
      id="modal-menu"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      :title="sidebarTitle"
      size="lg"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                v-model.trim="myObj.name"
                placeholder="Enter name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-for="(item, ind) in megaData" :key="ind">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                v-model.trim="item.title"
                placeholder="Enter title"
              />
            </b-form-group>
            <b-row v-for="(sub, index) in item.linkData" :key="index">
              <b-col md="4" class="pr-md-0">
                <b-form-group label="Name" invalid-feedback="Name is required.">
                  <b-form-input
                    v-model.trim="sub.name"
                    placeholder="Enter name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="pr-md-0">
                <b-form-group
                  label="Redirect To"
                  invalid-feedback="Redirect To is required."
                >
                  <v-select
                    v-model="sub.actionType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="actionOptions"
                    :reduce="(val) => val.value"
                    label="text"
                    :clearable="false"
                    placeholder="select"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="sub.actionType == 'page'" class="pr-md-0">
                <b-form-group label="Page" invalid-feedback="Page is required.">
                  <v-select
                    v-model="sub.pageID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    label="title"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    placeholder="select page"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="sub.actionType == 'url'" class="pr-md-0">
                <b-form-group label="URL" invalid-feedback="URL is required.">
                  <b-form-input
                    v-model.trim="sub.url"
                    placeholder="Enter url"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" class="mt-2">
                <b-button
                  v-if="index == item.linkData.length - 1"
                  @click="AddVal(ind)"
                  variant="primary"
                  class="btn-icon rounded-circle mr-50"
                >
                  <feather-icon size="18" icon="PlusIcon" />
                </b-button>
                <b-button
                  v-if="item.linkData.length > 1"
                  @click="removeVal(ind, index)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="18" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>

            <div class="text-center">
              <b-button
                v-if="ind == megaData.length - 1"
                @click="AddMega()"
                variant="primary"
                class="btn-icon rounded-circle mr-1"
              >
                <feather-icon size="18" icon="PlusIcon" />
              </b-button>
              <b-button
                v-if="megaData.length > 1"
                @click="removeMega(ind)"
                variant="outline-danger"
                class="btn-icon rounded-circle"
              >
                <feather-icon size="18" icon="XIcon" />
              </b-button>
            </div>
          </b-col>

          <b-col md="12" class="text-right">
            <b-button variant="success" @click="Add()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card no-body>
      <b-row class="p-2">
        <b-col>
          <b-button @click="OpenAddModal(0)" variant="primary" class="mr-1">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add Mega Menu</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="items"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="OpenAddModal(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteFlavour(data.item.id)"
            >
              <feather-icon size="16" icon="TrashIcon" />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BSpinner,
    BFormCheckbox,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      dataLoading: false,
      fields: ["#", { label: "name", key: "name" }, "actions"],
      items: [],
      request: false,
      myObj: {
        id: 0,
        name: "",
        content: "",
      },
      megaData: [],
      sidebarTitle: "",
      actionOptions: [
        // { text: "None", value: "none" },
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
      ],
      pageOptions: [],
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadPages();
    }
  },
  methods: {
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetails() {
      let state = true;
      this.megaData.forEach((el) => {
        if (el.title == "") {
          state = false;
        }
        el.linkData.forEach((ld) => {
          if (
            ld.name == "" ||
            ld.actionType == "" ||
            (ld.actionType == "page" && ld.pageID == 0) ||
            (ld.actionType == "url" && ld.url == "")
          ) {
            state = false;
          }
        });
      });
      return state;
    },
    checkAction() {
      var elem = this.$refs["redirect"];
      if (this.myObj.actionType == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPage() {
      if (this.myObj.actionType == "page") {
        var elem = this.$refs["page"];
        if (this.myObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckUrl() {
      if (this.myObj.actionType == "url") {
        var elem = this.$refs["url"];
        if (this.myObj.url == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    AddVal(ind) {
      this.megaData[ind].linkData.push({
        name: "",
        actionType: "",
        page: "",
        url: "",
        pageID: 0,
      });
    },
    removeVal(ind, fInd) {
      this.megaData[ind].linkData.splice(fInd, 1);
    },
    AddMega() {
      this.megaData.push({
        title: "",
        linkData: [{ name: "", actionType: "", page: "", url: "", pageID: 0 }],
      });
    },
    removeMega(ind) {
      this.megaData.splice(ind, 1);
    },
    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          name: "",
          content: "",
        };
        this.megaData = [
          {
            title: "",
            linkData: [
              { name: "", actionType: "", page: "", url: "", pageID: 0 },
            ],
          },
        ];

        this.sidebarTitle = "Add Mega Menu";
      } else {
        this.myObj = { ...id };
        this.megaData = JSON.parse(this.myObj.content);
        this.sidebarTitle = "Edit Mega Menu";
      }
      this.$bvModal.show("modal-menu");
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/MegaMenu/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.$bvToast.toast("Mega Menu has been deleted.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.LoadData();
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },

    Add() {
      this.CheckName();
      this.CheckDetails();
      if (this.CheckName() == false || this.CheckDetails() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.myObj.content = JSON.stringify(this.megaData);
        // console.log(this.myObj);
        this.request = true;
        if (this.myObj.id == 0) {
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/MegaMenu",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                this.$bvToast.toast("Mega Menu added successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                this.LoadData();
                this.$bvModal.hide("modal-menu");
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        } else {
          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/MegaMenu/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                this.$bvModal.hide("modal-menu");
                this.LoadData();

                this.$bvToast.toast("Mega Menu updated successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        }
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/MegaMenu",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.pageOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
